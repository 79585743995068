import React from "react"
import SectionBase from "../base";
import { Title } from "../../atoms/titles";
import { Description } from "../../atoms/text";
import Grid2 from "../../grids/grid2"
import { ProductCardExample } from "../../cards/product"

const SectionGrid2 = ({
  title = '',
  description = '',
  className = "pt-32 pb-12 lg:pt-48 lg:pb-32",
  classNameGrid = "gap-8 mx-8",
  classNameTitle = "text-center",
  style = {},
  children
}) => {
  return (
    <SectionBase className={className}>
      <Title className={classNameTitle}>{title}</Title>
      <Grid2 className={classNameGrid}>
        {children}
      </Grid2>
    </SectionBase>

  )
}

// full width, with image bg or video in the background
const SectionGrid2Example = () => {
  return (
    <SectionGrid2
      title="Grid 2 section mummy"
      className="pt-32 pb-12 lg:py-24 bg-pink-200"
      classNameGrid="gap-16 mx-32"
      classNameTitle="text-[60px] text-center"
    >
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <CardDummy />
      <CardDummy />
      <CardDummy />
    </SectionGrid2>

  )
}

const CardDummy = () => {

  return (
    <div>
      <div>icon</div>
      <Title>Title</Title>
      <Description>this is the descripoption</Description>
    </div>
  )
}

export default SectionGrid2;
export { SectionGrid2Example };
