import React from "react"
import Grid from "./base"

const Grid3 = ({ className = "gap-12", children }) => {
  return (
    <Grid className={'grid lg:grid-cols-3 ' + className}>
      {children}
    </Grid>
  )
}


export default Grid3