import React from "react"
import ProductCard from "../cards/product"
import SectionGrid3 from "./grid/grid3"



const SectionTopCategories = ({
  items = [],
  className = "relative lg:top-[-50px] lg:bottom-[-50px] z-10 "
}) => {

  const cards = items.map((item, index) => {

    const cta = {
      label: item.label,
      url: item.path.alias
    }

    const image2 = {
      url: item.relationships.image.localFile.publicURL,
      alt: item.image.alt
    }

    return (
      <ProductCard
        className="bg-white lg:p-8 text-center lg:text-right "
        key={index}
        title={item.name}
        subtitle={''}
        image={image2}
        cta={cta}
      ></ProductCard>
    )
  })

  return (
    <SectionGrid3
      className={className}
      classNameGrid="bg-transparent gap-16  lg:gap-8 mx-8 my-8 lg:my-0 grid-cols-1 lg:grid-cols-3 "
    >
      {cards}
    </SectionGrid3>

  )
}


export default SectionTopCategories;

