import React from "react"
import SectionBase from "./base";
import { Title } from "../atoms/titles";
import { Description } from "../atoms/text";
import { Link } from "gatsby"


const SectionFeature = ({
  title = '',
  description = '',
  cta = {
    label: "read more",
    url: "/"
  },
  className = "pt-32 pb-12 lg:pt-48 lg:pb-32",
  style = {},
  children
}) => {
  return (
    <SectionBase className={className} style={style} >
      <div className="container">
        <Title className="text-[40px] lg:text-[60px]  font-bold">{title}</Title>
        <Description className="text-lg lg:text-[22px] leading-6 ">{description}</Description>
        {cta && <Link to={cta.url} className="button-white">{cta.label}</Link>}
        {children}
      </div>
    </SectionBase>
  )
}


const SectionFeatureExample = () => {
  return (
    <SectionFeature
      className="pt-32 pb-12 lg:pt-48 lg:pb-32 text-white"
      style={{ backgroundImage: 'url("https://picsum.photos/1980/470")' }}
      title="רשום אותי"
      description="בל הצעות מיוחדות, חדשות מוצר בלעדיות ומידע על אירועים ישירות לתיבת הדואר הנכנס שלך"
      cat={{
        label: "כפתור קריאה לפעולה",
        url: "/"
      }}
    />

  )
}


export default SectionFeature;
export { SectionFeatureExample };
