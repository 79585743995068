import { graphql, useStaticQuery } from "gatsby"

const useTopCategories = () => {

  const data = useStaticQuery(graphql`
    query {
      items: blockContentCategories(id: {eq: "da329b2b-1c5c-58f0-b6c9-d8f59affcec4"}) {
        relationships {
          category: field_category {
            id
            name
            path {
              alias
            }
            image: field_teaser_image {
              alt
            }
            label: field_cta_title
            relationships {
              image: field_teaser_image {
                ...Image
              }
            }
          }
        }
      }
    }
  `)

  return data.items.relationships.category

}

export default useTopCategories
