import React from "react"
import Grid from "./base"

const Grid2 = ({ className = "gap-12", children }) => {
  return (
    <Grid className={'grid lg:grid-cols-2 ' + className}>
      {children}
    </Grid>
  )
}


const Grid2Example = ({ className = "gap-12", children }) => {
  return (
    <Grid2 className='py-20 bg-red-600 text-white'>
      <div className="border h-24 text center ">item 1</div>
      <div className="border h-24 text center ">item 2</div>
      <div className="border h-24 text center ">item 3</div>
    </Grid2>
  )
}


export default Grid2
export { Grid2Example }