import * as React from "react"
import { Link } from "gatsby"
//import Image from "../Image"
import PropTypes from 'prop-types'
import Card from './base'
import { Title, SubTitle } from "../atoms/titles"
import { Description, Html } from "../atoms/text"

const StroyCard = ({
  title,
  description,
  html,
  date,
  image = {
    url: '',
    alt: '',
  },
  className = '',
  classNameImage = '',
  classNameBody = '',
  classNameDate = 'text-[20px] text-[#707070]',
  classNameHtml = '',
  classNameTitle = '',
  children,
  ...props
}) => {

  //console.log('classNameImage', classNameImage)

  return (
    <Card className={className}>
      <div className="hidden lg:block lg:h-[516px] bg-bottom lg:bg-cover " 
       style={{ backgroundImage: `url("${image.url}")`}}
      />
      <div className="lg:hidden">
        <img src={image.url } alt="" /> 
      </div>
      <div className={classNameBody}>
        <Title className={"text-2xl lg:text-[40px] font-bold " + classNameTitle }>{title}</Title>
        {date && <SubTitle className={classNameDate}>{date}</SubTitle>}
        {description && <Description>{html}</Description>}
        {html && <Html className={classNameHtml}>{html}</Html>}
        {children}
      </div>
    </Card>
  )
}


StroyCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
  classNameImage: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  })
};

StroyCard.defaultProps = {
  title: '',
  description: '',
  date: '',
  html: '',
  className: '',
  classNameImage: '',
  image: {
    url: '/', //'https://picsum.photos/484/272',
    alt: 'some thing'
  },
};

const StoryCardExample = () => {

  return <StroyCard
    title="כותרת זיפור"
    //description="בב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התיד"
    html="<p>שף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא ה</p> <br/> <strong>אאא</strong>"
    image={{
      url: 'https://picsum.photos/917/516?random=22',
      alt: 'some thing'
    }}
    className="border py-9 px-8"
    classNameImage="h-[516px]"
  >
    <Link to="/">קרא עוד</Link>
  </StroyCard>
}

export default StroyCard
export { StoryCardExample }
