import React from "react"
//import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SectionGrid2 from "../components/sections/grid/grid2"
import SectionBase from "../components/sections/base"
import SectionFeature from "../components/sections/feature"
import StroyCard from "../components/cards/story"
//import HeroFront from "../components/sections/hero/front"
import HeroInner2 from "../components/sections/hero/inner2"
import useTopCategories from "../hooks/useTopCategories"
import SectionTopCategories from "../components/sections/top-categories"


const IndexPage = ({ data: { nodeHomepage } }) => {

  return (
    <Layout>
      <Hero data={nodeHomepage.relationships.hero} />
      <Section1 />
      <Section2 data={nodeHomepage.relationships.section2} />
      <Section3
        data={nodeHomepage.relationships.stories}
        title={nodeHomepage.stories_title}
      />
      <Section4 data={nodeHomepage.relationships.section4} />
    </Layout>
  )
}


const Hero = ({ data }) => {

  //console.log(data)

  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL
  const action1 = data.relationships.actions[0]


  let backgrounds = []
  backgrounds = data.relationships.images.map( (m) => ( m.localFile.publicURL  ))
  if( backgrounds.length === 0 ){
    backgrounds.push(url)
  }

  // const actions = data.relationships.actions.map((item, index) => (
  //   <div key={index}>
  //     <Link

  //       className={index % 2 === 0 ? "button-white" : "button-primary"}
  //       to={item.url}>
  //       {item.label}
  //     </Link>
  //   </div>
  // ))



  return (
    <>
    
    <HeroInner2
      title={title}
      //style={{ backgroundImage: `url("${url}")` }}
      backgrounds={backgrounds}
      description={subtitle}
      action1={action1}
    />
    
    {/* <HeroFront
      className='h-[300px] lg:h-[950px] text-white py-12 '
      title={title}
      description={subtitle}
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${url}")`}}
    >
      {actions}
    </HeroFront> */}
    </>
  )
}

const Section1 = ({ data }) => {

  const items = useTopCategories()

  return (
    <SectionTopCategories items={items} />
  )
}


const Section2 = ({ data }) => {

  const { title, subtitle } = data
  const url = data?.relationships?.image?.localFile?.publicURL

  return (
    <SectionFeature
      className="pt-32 pb-12 lg:pt-48 lg:pb-32 text-white"
      style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("${url}")` }}
      title={title}
      description={subtitle}
      cta={false}
    />

  )

}

const Section3 = ({ data, title }) => {


  const cards = data.map((item, index) => {

    const image = {
      url: item.relationships.image.localFile.publicURL,
      alt: item.image.alt
    }

    return (
      <StroyCard
        className="py-0 px-0"
        key={index}
        title={item.title}
        html={item.description[0].processed}
        image={image}
        classNameImage="--h-[516px] w-full"
        classNameBody="pt-4 pb-12 lg:py-6 px-10 font-extralight "
      />
    )
  })

  return (
    <SectionGrid2
      className="bg-dark text-white lg:pb-12"
      classNameTitle="text-3xl font-bold lg:font-normal lg:text-[60px] leading-6 lg:leading-10 text-center py-10"
      classNameGrid="gap-0 p-0 grid-cols-1 lg:grid-cols-2"
      title={title}
    >
      {/* <StoryCardExample /> */}
      {cards}
    </SectionGrid2>
  )
}


const Section4 = ({ data }) => {


  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL

  return (
    <SectionBase
      className=" text-white bg-bottom bg-cover "
      //style={{ backgroundImage: `url("${url}")` }}
      title={title}
      description={subtitle}
      cta={false}
    >
    <img src={url} alt="" />
  
      
      </SectionBase>

  )

}


export default IndexPage


export const query = graphql`
  query homepage {
    nodeHomepage(id: {eq: "762c67c0-d688-5173-ac4c-e2288d909949"}) {
      title
      id
      stories_title: field_section_title
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section2: field_hero_2 {
          ...HeroContent
        }
        section4: field_hero_3 {
          ...HeroContent
        }
        stories: field_paragraph_x_2 {
          ...BlockContent
        }
      }
    }
  }
`
