import React from "react"
import SectionBase from "../base";
import { Title } from "../../atoms/titles";
import { Description } from "../../atoms/text";

import Grid3 from "../../grids/grid3"
import ProductCard from "../../cards/product"

const SectionGrid3 = ({
  title = '',
  description = '',
  className = "pt-32 pb-12 lg:pt-48 lg:pb-32",
  classNameGrid = "gap-8 mx-8",
  style = {},
  children
}) => {
  return (
    <SectionBase className={className} >

      <Grid3 className={classNameGrid}>
        {children}
      </Grid3>
    </SectionBase>

  )
}

// full width, with image bg or video in the background
const SectionGrid3Dummy = () => {
  return (
    <SectionGrid3
      className="pt-32 pb-12 lg:py-24 bg-pink-200"
      classNameGrid="gap-16 mx-32"
    >
      <ProductCard className="bg-white p-2" />
      <ProductCard className="bg-white p-2" />
      <ProductCard className="bg-white p-2" />
      <ProductCard className="bg-white p-2" />
      <ProductCard className="bg-white p-2" />
      <ProductCard className="bg-white p-2" />
      <CardDummy />
      <CardDummy />
      <CardDummy />
    </SectionGrid3>

  )
}

const CardDummy = () => {

  return (
    <div>
      <div>icon</div>
      <Title>Title</Title>
      <Description>this is the descripoption</Description>
    </div>
  )
}

export default SectionGrid3;
export { SectionGrid3Dummy };
